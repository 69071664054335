<template>

<div class="list">

	<div class="list-head" v-if="block.search || block.tags">

		<com-search v-if="block.search" v-model="model.search" :full="!block.tags" />
		<com-tags v-if="block.tags" :tags="block.tags" :labels="tags" v-model="model.tags" />

	</div>

	<div class="list-content" :class="{'is-loading': is.loading}">

		<slot></slot>

		<div v-if="!total && !is.loading" class="list-content-empty">Nothing found.</div>

	</div>

	<div class="list-foot" v-if="block.perpage">

		<com-paginate v-if="block.perpage" :current="model.page" :total="total" :perpage="block.perpage" v-on:change="onPaginate" />

	</div>

</div>

</template>

<script>

export default {

	props: ['block', 'tags', 'api'],

	components: {
		'com-search': () => import('./list/Search'),
		'com-tags': () => import('./list/Tags'),
		'com-paginate': () => import('./list/Paginate'),
	},

	data: function() {

		return {
			is: {
				loading: true
			},
			model: {
				search: '',
				tags: [],
				page: 1
			},
			total: 0
		}

	},

	created: function() {

		this.load()

	},

	watch: {

		model: {

			deep: true,

			handler: function() {

				this.load()

			}

		}

	},

	methods: {

		onPaginate: function(page) {

			this.model.page = page

		},

		load: function() {

			this.is.loading = true

			this.$api.cancel(this.api)

			this.$api.get(this.api, {
				page: this.model.page,
				search: this.model.search,
				limit: this.block.limit,
				perpage: this.block.perpage,
				tags: this.model.tags.join(','),
				applicableTags: (this.block.tags) ? this.block.tags.join(',') : '',
				context: (this.block.context) ? this.$store.getters['context'].id : 0,
				region: (this.block.context) ? this.$store.getters['language'].id : 0,
				source: this.block.source,
				sourceID: this.block.sourceID
			}, this.block.id).then(function(json) {

				this.total = json.total

				this.$emit('loaded', json.list)
				this.$emit('response', json)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.list-head {
	margin-bottom: 40px;
}

.list-content {
	display: flex;
	flex-wrap: wrap;
	min-height: 100px;
}

.list-content.is-loading {
	background-image: url(~@/assets/load.gif);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.list-content.is-loading >>> div {
	opacity: 0;
}

.list-content-empty {
	font-size: 20px;
	color: #ccc;
	line-height: 100px;
	width: 100%;
	text-align: center;
}

.list-foot {
	margin-top: 40px;
}

</style>
